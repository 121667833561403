import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Contact" />

    <div>
      <h1>
        Contact <span className="text-blue-600">Me</span>
      </h1>
      <p>I can be contacted via telephone or email.</p>
      <p>Phone: 07544 269118</p>
      <p>Email: enquiries@margaretheap.co.uk</p>
      <p>Counselling in Accrington, Lancashire.</p>
    </div>
  </Layout>
)

export default IndexPage
